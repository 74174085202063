import { getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { useFetcher } from 'react-router'
import { useTranslation } from 'react-i18next'
import { getIconName } from '~/components/icon/icon.helper'
import { Icon } from '~/components/icon/icon'
import { useTheme } from '~/hooks/useTheme'
import { ThemeFormSchema } from '~/types/setTheme'
import { Button } from '../button/button'

export function ThemeSwitchIcon() {
	const fetcher = useFetcher()
	const { t } = useTranslation()
	const [form] = useForm({
		id: 'theme-switch-icon',
		lastResult: fetcher?.data?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: ThemeFormSchema })
		},
	})

	const mode = useTheme()
	const nextMode = mode === 'light' ? 'dark' : 'light'

	return (
		<fetcher.Form method="POST" action="/api/set-theme" {...getFormProps(form)}>
			<input type="hidden" name="theme" value={nextMode} />
			<Button
				type="submit"
				variant="ghost"
				title={t('action_change_theme')}
				className="aspect-square !rounded-full !p-0"
			>
				<Icon
					name={getIconName(mode === 'dark' ? 'sun' : 'moon')}
					className="text-body-md"
				/>
			</Button>
		</fetcher.Form>
	)
}
