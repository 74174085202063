import { useTranslation } from 'react-i18next'
import { Icon } from '../icon/icon'
import Text from '../text/text'
import { NavLink } from '~/components/internal-nav-link/Internal-nav-link'
import { useOptionalUser } from '~/utils/user'

export default function MobileHeader() {
	const user = useOptionalUser()
	const { t } = useTranslation()
	return (
		<nav className="fixed bottom-0 z-20 block w-full rounded-t-lg border-t border-border bg-background pt-1 text-foreground shadow-sm md:hidden">
			<div className="flex items-center justify-between">
				<NavLink className="flex w-full flex-col py-2 text-center" to="/" end>
					{({ isActive }) => (
						<>
							<Icon
								className={isActive ? 'text-secondary' : ''}
								size="md"
								name="home"
							/>
							<Text as="sm">{t('misc_home')}</Text>
						</>
					)}
				</NavLink>
				{user ? (
					<>
						<NavLink
							className="flex w-full flex-col py-2 text-center"
							to="/favorites"
						>
							{({ isActive }) => (
								<>
									<Icon
										className={isActive ? 'text-secondary' : ''}
										size="md"
										name="heart"
									/>
									<Text as="sm">{t('misc_favorites')}</Text>
								</>
							)}
						</NavLink>
						<NavLink
							className="flex w-full flex-col py-2 text-center"
							to="/my-events"
						>
							{({ isActive }) => (
								<>
									<Icon
										className={isActive ? 'text-secondary' : ''}
										size="md"
										name="calendar"
									/>
									<Text as="sm" className="whitespace-nowrap">
										{t('profile_my_events')}
									</Text>
								</>
							)}
						</NavLink>
						<NavLink
							className="flex w-full flex-col py-2 text-center"
							to="/profile"
						>
							{({ isActive }) => (
								<>
									<Icon
										className={isActive ? 'text-secondary' : ''}
										size="md"
										name="avatar"
									/>
									<Text as="sm">{t('misc_profile')}</Text>
								</>
							)}
						</NavLink>
					</>
				) : (
					<>
						<NavLink
							className="flex w-full flex-col py-2 text-center"
							to="/info"
						>
							{({ isActive }) => (
								<>
									<Icon
										className={isActive ? 'text-secondary' : ''}
										size="md"
										name="exclamation-circle"
									/>
									<Text as="sm">{t('misc_info')}</Text>
								</>
							)}
						</NavLink>
						<NavLink
							className="flex w-full flex-col py-2 text-center"
							to="/login"
						>
							{({ isActive }) => (
								<>
									<Icon
										className={isActive ? 'text-secondary' : ''}
										size="md"
										name="avatar"
									/>
									<Text as="sm">{t('action_login')}</Text>
								</>
							)}
						</NavLink>
						<NavLink
							className="flex w-full flex-col py-2 text-center"
							to="/signup"
						>
							{({ isActive }) => (
								<>
									<Icon
										className={isActive ? 'text-secondary' : ''}
										size="md"
										name="key"
									/>
									<Text as="sm">{t('signup_become_member')}</Text>
								</>
							)}
						</NavLink>
					</>
				)}
			</div>
		</nav>
	)
}
