import { useTranslation } from 'react-i18next'
import Chip from '../chip/Chip'
import { getIconName } from '../icon/icon.helper'
import { Icon } from '../icon/icon'
import type { EventCategory } from '~/types/eventCategory'

export default function HeaderSearchCategories({
	categories,
	setCategories,
	allCategories,
}: {
	categories: string[]
	setCategories: (categories: string[]) => void
	allCategories: EventCategory[]
}) {
	const { t } = useTranslation()
	const handleChange = (value?: string) => {
		if (!value) {
			setCategories([])
		} else {
			if (categories.includes(value)) {
				const updatedList = categories.filter(x => x !== value && x !== 'all')
				setCategories(updatedList)
			} else {
				const updatedList = [...categories, value]
				setCategories(updatedList)
			}
		}
	}

	return (
		<>
			<div className="mt-4">
				<div className="relative">
					{categories.map(x => (
						<input key={x} hidden readOnly name="category" value={x} />
					))}
					<button
						type="button"
						onClick={() => handleChange()}
						className="m-1 inline-block cursor-pointer duration-100 hover:opacity-80 focus:outline-1"
					>
						<Chip
							title={t('misc_all_categories')}
							color={categories.length === 0 ? 'secondary' : 'slate'}
							text={
								<div className="flex items-center">
									<Icon name="globe" size="xs" className="mr-1" />
									{t('misc_all_categories')}
								</div>
							}
						/>
					</button>
					{allCategories.map(category => (
						<button
							type="button"
							className="m-1 inline-block cursor-pointer duration-100 hover:opacity-80 focus:outline-1"
							key={category.id}
							onClick={() => handleChange(category.alias)}
						>
							<Chip
								title={category.name}
								color={
									categories.includes(category.alias) ? category.color : 'slate'
								}
								text={
									<div className="flex items-center">
										<Icon
											name={getIconName(category.icon)}
											size="xs"
											className="mr-1"
										/>
										{category.name}
									</div>
								}
							/>
						</button>
					))}
				</div>
			</div>
		</>
	)
}
