/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, useSubmit } from 'react-router'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkButton } from '../button/link-button'
import Container from '../container/container'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from '../dropdown-menu/dropdown-menu'
import HeaderSearch from '../header-search/header-search'
import { Icon } from '../icon/icon'
import { Link } from '../internal-link/Internal-link'
import LanguageSwitch from '../language-switch/language-switch'
import LoginModal from '../login-modal/login-modal'

import { useClientLang } from '~/utils/i18n.utils'
import { getImgSrc } from '~/utils/image'
import { cn } from '~/utils/misc'
import { useIsAdmin, useOptionalUser, useUser } from '~/utils/user'
import { ThemeSwitchIcon } from '../theme-switch/theme-switch-icon'

export default function Header({ classes }: { classes?: string | undefined }) {
	const [scrollHide, setScrollHide] = useState(true)
	const [lastScrollY, setLastScrollY] = useState(81)

	const controlNavbar = () => {
		if (window.scrollY > lastScrollY && window.scrollY > 1000) {
			setScrollHide(false)
		} else {
			setScrollHide(true)
		}
		setLastScrollY(window.scrollY)
	}

	const user = useOptionalUser()
	const [modalPage, setModalPage] = useState<number | undefined>(undefined)

	const toggleLoginModal = (value?: number) => {
		setModalPage(value)
	}

	useEffect(() => {
		window.addEventListener('scroll', controlNavbar)

		// cleanup function
		return () => {
			window.removeEventListener('scroll', controlNavbar)
		}
	}, [lastScrollY])
	return (
		<header
			className={cn(
				'fixed top-0 z-10 w-full border-b bg-background py-4 text-foreground shadow-sm transition-all delay-150 duration-300',
				classes,
				scrollHide ? 'top-0' : 'top-0 md:top-[-81px]',
			)}
		>
			{modalPage !== undefined && (
				<LoginModal
					initialPage={modalPage}
					toggleLoginModal={toggleLoginModal}
				/>
			)}
			<Container marginY={false}>
				<nav className="flex items-center justify-between">
					<Link
						to="/"
						className="hidden text-2xl font-extrabold tracking-wide md:block"
					>
						<span className="text-foreground">EVENT</span>
						<span className="text-secondary">
							LY
							{ENV.MODE === 'development' && (
								<sup className="ml-2 text-sm font-extrabold text-foreground">
									<sup>{'<DEV>'}</sup>
								</sup>
							)}
						</span>
					</Link>
					<HeaderSearch />
					<div className="hidden !w-min items-center justify-between gap-2 md:flex">
						<ThemeSwitchIcon />
						<LanguageSwitch />
						{user ? <UserDropdown /> : <LoggedOutDropdown />}
					</div>
				</nav>
			</Container>
		</header>
	)
}

function LoggedOutDropdown() {
	const [showLoginModal, setShowLoginModal] = useState(false)
	const [showSignupModal, setShowSignupModal] = useState(false)
	const { t } = useTranslation()
	const toggleLoginModal = () => setShowLoginModal(prev => !prev)
	const toggleSignupModal = () => setShowSignupModal(prev => !prev)

	return (
		<>
			{showLoginModal && (
				<LoginModal toggleLoginModal={toggleLoginModal} initialPage={0} />
			)}
			{showSignupModal && (
				<LoginModal toggleLoginModal={toggleSignupModal} initialPage={2} />
			)}
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<LinkButton
						variant="outline"
						size="md"
						className="flex items-center whitespace-nowrap !rounded-full pl-8 pr-5"
						to={`/`}
						onClick={e => e.preventDefault()}
					>
						<Icon name="menu" className="text-body-md">
							<span className="sr-only">{t('misc_menu')}</span>
						</Icon>
						<img
							className="m h-8 w-8 flex-shrink-0 rounded-full object-cover"
							alt="Placeholder bild för användare"
							src="/img/user.png"
							loading="lazy"
						/>
					</LinkButton>
				</DropdownMenuTrigger>
				<DropdownMenuPortal>
					<DropdownMenuContent sideOffset={8} align="end">
						<DropdownMenuItem asChild>
							<button onClick={toggleLoginModal} className="w-full">
								{t('action_login')}
							</button>
						</DropdownMenuItem>
						<DropdownMenuItem asChild>
							<button onClick={toggleSignupModal} className="w-full">
								{t('signup_become_member')}
							</button>
						</DropdownMenuItem>
						<hr />
						<DropdownMenuItem asChild>
							<Link to="/info">{t('info_how_it_works')}</Link>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenuPortal>
			</DropdownMenu>
		</>
	)
}

function UserDropdown() {
	const user = useUser()
	const isAdmin = useIsAdmin()
	const submit = useSubmit()
	const { t } = useTranslation()
	const formRef = useRef<HTMLFormElement>(null)
	const lang = useClientLang()
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<LinkButton
					variant="outline"
					size="md"
					className="flex items-center whitespace-nowrap !rounded-full pl-8 pr-5"
					to={`/users/${user.id}`}
					onClick={e => e.preventDefault()}
				>
					<Icon name="menu" className="text-body-md">
						<span className="sr-only">{t('misc_menu')}</span>
					</Icon>
					<img
						className="m h-8 w-8 flex-shrink-0 rounded-full object-cover"
						alt={user.name ?? ''}
						src={getImgSrc(user.imageId, 'user')}
						loading="lazy"
					/>
				</LinkButton>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="end">
					<DropdownMenuItem asChild>
						<Link to="/profile">
							<Icon className="mr-1 text-body-md" name="avatar">
								{t('misc_profile')}
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link to="/my-events">
							<Icon className="mr-1 text-body-md" name="calendar">
								{t('info_my_events')}
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link to="/favorites">
							<Icon className="mr-1 text-body-md" name="heart">
								{t('misc_favorites')}
							</Icon>
						</Link>
					</DropdownMenuItem>
					{isAdmin && (
						<DropdownMenuGroup>
							<DropdownMenuItem asChild>
								<Link to="/profile/statistics">
									<Icon className="mr-1 text-body-md" name="bar-chart">
										{t('misc_statistics')}
									</Icon>
								</Link>
							</DropdownMenuItem>
						</DropdownMenuGroup>
					)}
					{isAdmin && (
						<DropdownMenuItem asChild>
							<Link to="/admin">
								<Icon className="mr-1 text-body-md" name="lock">
									{t('misc_admin')}
								</Icon>
							</Link>
						</DropdownMenuItem>
					)}
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={(event: any) => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<div className="broder-t-border border-t">
							<Form
								action={`/logout?lang=${lang.lngShort}`}
								method="POST"
								ref={formRef}
							>
								<input type="hidden" name="lang" value={lang.lngShort} />
								<button
									type="submit"
									className="relative flex w-full select-none items-center rounded-sm text-sm outline-none transition-colors hover:bg-accent/40 hover:text-accent-foreground focus:bg-accent/40 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
								>
									<Icon className="mr-1 text-body-md" name="exit">
										{t('action_logout')}
									</Icon>
								</button>
							</Form>
						</div>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}
