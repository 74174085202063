import { useEffect } from 'react'
import { toast } from '~/components/ui/use-toast'
import type { ToastMessage } from '~/types/toast'

export const useToast = (message?: ToastMessage) => {
	useEffect(() => {
		if (message) {
			toast({
				variant: message.variant,
				title: message.title,
				description: message.description,
			})
		}
	}, [message])
}
