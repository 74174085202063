import {
	createCookie,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	type HeadersFunction,
	useLocation,
	redirect,
	data,
} from 'react-router'
import fontStylestylesheetUrl from './styles/font.css?url'
import tailwindStylesheetUrl from './styles/tailwind.css?url'
import swiperBundleStyling from 'swiper/swiper-bundle.css?url'
import type { Route } from './+types/root'
import { getEnv } from './utils/env.server'
import { getEventCategories } from './queries/getCategories.server'
import { getServerLang } from './utils/i18n.utils'
import { makeTimings, time } from './utils/timing.server'
import { useLoaderData } from 'react-router'
import { cachified, lruCache } from './utils/cache/cache.server'
import { combineHeaders, getDomainUrl } from './utils/misc'
import { getTheme } from './utils/theme.server'
import { ClientHintCheck, getHints } from './utils/client-hints'
import { Toaster } from './components/ui/toaster'
import { prisma } from './utils/db.server'
import { useChangeLanguage } from 'remix-i18next/react'
import { useTranslation } from 'react-i18next'
import Footer from './components/footer/footer'
import { honeypot } from './utils/honeypot.server'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import {
	isInfoRoute,
	isMapRoute,
	isPlaceRoute,
	isRootRoute,
} from './utils/location/location.route.helpers'
import { preLocation } from './utils/location/location.server'
import Header from './components/header/header'
import MobileHeader from './components/header/mobile-header'
import { useTheme } from './hooks/useTheme'
import { getUserId, logout } from './utils/auth/session.server'
import { LoadingIndicator } from './components/loading-indicator/loading-indicator'
import CookieBanner from './components/cookie-banner/cookie-banner'
import { getFlashSession } from './utils/flash-session.server'
import { useToast } from './utils/useToast'
import { ConfettiComponent } from './components/confetti/confetti'
import { useEffect } from 'react'
import * as gtag from '~/utils/gtags.client'
export const links: Route.LinksFunction = () => [
	// Preload svg sprite as a resource to avoid render blocking
	// Preload CSS as a resource to avoid render blocking
	{ rel: 'preload', href: fontStylestylesheetUrl, as: 'style' },
	{ rel: 'preload', href: tailwindStylesheetUrl, as: 'style' },
	{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
	{
		rel: 'icon',
		type: 'image/png',
		href: '/favicons/favicon-32x32.png',
	},
	{
		rel: 'apple-touch-icon',
		sizes: '180x180',
		href: '/favicons/apple-touch-icon.png',
	},
	{
		rel: 'manifest',
		href: '/site.webmanifest',
		crossOrigin: 'use-credentials',
	} as const, // necessary to make typescript happy
	{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
	{ rel: 'stylesheet', href: swiperBundleStyling },
	{ rel: 'stylesheet', href: fontStylestylesheetUrl },
	{ rel: 'stylesheet', href: tailwindStylesheetUrl },
]

export async function loader({ request }) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'get userId',
	})
	const gdprConsent = createCookie('gdpr-consent', {
		maxAge: 31536000, // One Year
		domain: process.env.COOKIE_DOMAINS ?? undefined,
	})
	const cookieHeader = request.headers.get('Cookie')
	const gdprConsentCookie = (await gdprConsent.parse(cookieHeader)) || {}
	const preLocationCookie = (await preLocation.parse(cookieHeader)) || {}
	const url = new URL(request.url)
	const origin = url.origin

	const lng = getServerLang(request)

	const user = userId
		? await time(
				() =>
					prisma.user.findUnique({
						where: { id: userId },
						select: {
							id: true,
							name: true,
							imageId: true,
							roles: true,
							email: true,
							newsletterSubscriber: true,
							favorites: {
								select: {
									id: true,
								},
							},
						},
					}),
				{ timings, type: 'get current user' },
			)
		: null
	if (userId && !user) {
		throw redirect('/', { headers: await logout({ request }) }) // TODO: redirect to correct language
	}
	const { flash, headers: flashHeaders } = await getFlashSession(request)
	const categories = await time(
		() =>
			cachified({
				key: `root:categories:${lng.lngShort}`,
				cache: lruCache,
				timings,
				ttl: 1000 * 60 * 60 * 2, // 2 hours
				getFreshValue: async () => {
					return await getEventCategories(lng.lngShort)
				},
			}),
		{ timings, type: 'get categories' },
	)
	const hints = getHints(request)
	const domainOrigin = getDomainUrl(request)
	const theme = getTheme(request)
	const ENV = getEnv()

	return data(
		{
			user,
			requestInfo: {
				hints,
				origin: domainOrigin,
				path: new URL(request.url).pathname,
				userPrefs: {
					theme,
				},
			},
			locale: lng,
			origin,
			ENV,
			flash,
			currentUrl: url.href,
			categories,
			track: gdprConsentCookie.gaConsent,
			cookieConsent: gdprConsentCookie.gdprConsent,
			userCoordinates: preLocationCookie.location,
			honeypotInputProps: await honeypot.getInputProps(),
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				flashHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

export function Layout() {
	const { locale, currentUrl, ENV, cookieConsent, flash, honeypotInputProps } =
		useLoaderData<typeof loader>()
	const { i18n } = useTranslation()
	useChangeLanguage(locale.lngShort)
	useToast(flash?.toast)
	const gaTrackingId = ENV?.GOOGLE_ANALYTICS_KEY
	const theme = useTheme()
	const location = useLocation()
	const isInfoPage = isInfoRoute(location.pathname)
	const isMapPage = isMapRoute(location.pathname)
	const showHeaderOnMobile =
		isRootRoute(location.pathname) ||
		isMapPage ||
		isPlaceRoute(location.pathname)
	const hideFooterOnMobile = /(favorites|my-events)/.test(location.pathname)
	useEffect(() => {
		if (process.env.NODE_ENV === 'production' && gaTrackingId) {
			gtag.pageview(location.pathname, gaTrackingId)
		}
	}, [location, gaTrackingId])
	return (
		<HoneypotProvider {...honeypotInputProps}>
			<html
				lang={locale.lngShort}
				dir={i18n.dir()}
				className={`${theme} flex h-full flex-col overflow-x-hidden`}
			>
				<head>
					<ClientHintCheck />
					<Meta />
					<meta charSet="utf-8" />
					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<meta
						name="google-adsense-account"
						content="ca-pub-6258776975581886"
					/>
					<meta
						name="theme-color"
						media="(prefers-color-scheme: light)"
						content="#fcfcfc" // Taken from tailwind.css --background
					/>
					<meta
						name="theme-color"
						media="(prefers-color-scheme: dark)"
						content="#161b22" // Taken from tailwind.css --background
					/>
					<meta name="og:locale" content={locale.lngLong} />
					<meta name="og:url" content={currentUrl} />
					<Links />
				</head>
				<body className="bg-background text-foreground">
					<div className="flex h-[calc(100dvh)] flex-col justify-between md:h-screen">
						<LoadingIndicator />
						{!isInfoPage && (
							<Header
								classes={!showHeaderOnMobile ? 'max-md:hidden' : undefined}
							/>
						)}
						{!isMapPage && <MobileHeader />}
						<main className="flex-1">
							<Outlet />
						</main>
						{cookieConsent ? null : <CookieBanner />}
						{!isInfoPage && !isMapPage && (
							<Footer
								classes={hideFooterOnMobile ? 'max-md:hidden' : undefined}
							/>
						)}
					</div>
					<div id="modal-root" />
					<ConfettiComponent confetti={flash?.confetti} />
					<Toaster />
					<ScrollRestoration />
					<Scripts />
					{process.env.NODE_ENV === 'production' && gaTrackingId && (
						<>
							<script
								async
								src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
							/>
							<script
								dangerouslySetInnerHTML={{
									__html: `
										window.dataLayer = window.dataLayer || [];
										function gtag(){dataLayer.push(arguments);}
										gtag('js', new Date());
										gtag('config', '${gaTrackingId}');
									`,
								}}
							/>
						</>
					)}
					{process.env.NODE_ENV === 'production' && (
						<script
							async
							src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6258776975581886"
							crossOrigin="anonymous"
						/>
					)}
					<script
						dangerouslySetInnerHTML={{
							__html: `window.ENV = ${JSON.stringify(ENV)}`,
						}}
					/>
				</body>
			</html>
		</HoneypotProvider>
	)
}

export default function App() {
	return <Outlet />
}
