/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Form,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import Modal from '../modal/modal'
import Text from '../text/text'
import HeaderSearchCategories from './header-search-categories'
import HeaderSearchDate from './header-search-date/header-search-date'
import HeaderSearchPlaces from './header-search-places'
import HeaderSearchText from './header-search-text'
import { useCategories } from '~/utils/categories'
import { useClientLang } from '~/utils/i18n.utils'
import { getCountryNameByCode } from '~/utils/location/location.helpers'
import {
	isMapRoute,
	isPlaceRoute,
	isRootRoute,
} from '~/utils/location/location.route.helpers'
import { useUserCoordinates } from '~/utils/location/location'
import { capitalizeText, cn } from '~/utils/misc'

export default function HeaderSearch() {
	const navigate = useNavigate()
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const [showModal, setShowModal] = useState<boolean>(false)
	const userCoordinates = useUserCoordinates()
	const allCategories = useCategories() ?? []
	const params = useParams()
	const [selectedTab, setSelectedTab] = useState<number>(0)

	const { t } = useTranslation()

	const dateFromQuery = searchParams.get('from')
	const dateToQuery = searchParams.get('to')

	const locationQuery = params.city ?? getCountryNameByCode(params.country)
	const swLatQuery = searchParams.get('swLat')
	const swLngQuery = searchParams.get('swLng')
	const neLatQuery = searchParams.get('neLat')
	const neLngQuery = searchParams.get('neLng')
	const nearbySelected = searchParams.get('nearby') === 'true'

	const categoriesQuery = searchParams.get('categories')
	const searchTextQuery = searchParams.get('s') ?? undefined
	const lang = useClientLang().lngShort
	const isMapPage = isMapRoute(location.pathname)
	const isRootPage = isRootRoute(location.pathname)
	const isPlacePage = isPlaceRoute(location.pathname)
	const showSearch = isRootPage || isMapPage || isPlacePage

	const [placeResult, setPlaceResult] = useState<any | null>()
	const [categories, setCategories] = useState<string[]>(
		categoriesQuery?.split('|') ?? [],
	)
	const [fromDate, setFromDate] = useState<Date | null>(
		dateFromQuery ? new Date(dateFromQuery) : null,
	)
	const [toDate, setToDate] = useState<Date | null>(
		dateToQuery ? new Date(dateToQuery) : null,
	)

	const [searchText, setSearchText] = useState<string | undefined>(
		searchTextQuery,
	)

	const getDatesText = useMemo(() => {
		let text = t('misc_all_dates')
		if (dateFromQuery !== null) {
			text = new Intl.DateTimeFormat('sv-SE', {
				day: '2-digit',
				month: 'short',
			}).format(new Date(dateFromQuery))
		}
		if (
			dateFromQuery !== null &&
			dateToQuery !== null &&
			dateFromQuery !== dateToQuery
		) {
			text +=
				' - ' +
				new Intl.DateTimeFormat('sv-SE', {
					day: '2-digit',
					month: 'short',
				}).format(new Date(dateToQuery))
		}
		return text
	}, [dateFromQuery, dateToQuery])

	const getLocationText = useMemo(() => {
		let text = t('public_everywhere')
		if (locationQuery) {
			text = capitalizeText(locationQuery)
		} else if (swLatQuery && swLngQuery && neLatQuery && neLngQuery) {
			text = t('public_map_area')
		} else if (
			userCoordinates?.latitude &&
			userCoordinates?.longitude &&
			nearbySelected
		) {
			text = t('public_near_you')
		}
		return text
	}, [
		locationQuery,
		swLatQuery,
		swLngQuery,
		neLatQuery,
		neLngQuery,
		userCoordinates,
	])

	const getSearchText = useMemo(() => {
		let text = t('action_search')
		if (searchTextQuery) {
			text = searchTextQuery
		}
		return text
	}, [searchTextQuery])

	useEffect(() => {
		if (categoriesQuery) {
			setCategories(categoriesQuery?.split('|') ?? [])
		} else {
			setCategories([])
		}
		if (!locationQuery) {
			setPlaceResult(null)
		}
		if (dateFromQuery) {
			setFromDate(new Date(dateFromQuery))
		} else {
			setFromDate(null)
		}
		if (dateToQuery) {
			setToDate(new Date(dateToQuery))
		} else {
			setToDate(null)
		}
		if (searchTextQuery) {
			setSearchText(searchTextQuery)
		} else {
			setSearchText(undefined)
		}
	}, [
		categoriesQuery,
		locationQuery,
		dateFromQuery,
		dateToQuery,
		searchTextQuery,
	])

	const search = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const newSearchParams = new URLSearchParams(searchParams.toString())
		if (categories?.length > 0) {
			newSearchParams.set('categories', categories.join('|'))
		} else {
			newSearchParams.delete('categories')
		}
		if (fromDate) {
			newSearchParams.set('from', fromDate.toLocaleDateString('sv-SE'))
		} else {
			newSearchParams.delete('from')
		}
		if (toDate) {
			newSearchParams.set('to', toDate.toLocaleDateString('sv-SE'))
		} else {
			newSearchParams.delete('to')
		}
		if (searchText) {
			newSearchParams.set('s', searchText)
		} else {
			newSearchParams.delete('s')
		}

		if (placeResult) {
			newSearchParams.set(
				'swLat',
				parseFloat(placeResult.viewport.swLat).toFixed(8),
			)
			newSearchParams.set(
				'swLng',
				parseFloat(placeResult.viewport.swLng).toFixed(8),
			)
			newSearchParams.set(
				'neLat',
				parseFloat(placeResult.viewport.neLat).toFixed(8),
			)
			newSearchParams.set(
				'neLng',
				parseFloat(placeResult.viewport.neLng).toFixed(8),
			)
			newSearchParams.delete('nearby')
		} else {
			if (!swLatQuery || !swLngQuery || !neLatQuery || !neLngQuery) {
				newSearchParams.delete('swLat')
				newSearchParams.delete('swLng')
				newSearchParams.delete('neLat')
				newSearchParams.delete('neLng')
			}
		}

		if (placeResult) {
			const country = placeResult?.countryCode.toLowerCase()
			const city = placeResult?.name.toLowerCase()
			newSearchParams.delete('nearby')

			if (!isMapPage) {
				const path =
					country && city
						? `${lang === 'sv' ? '' : `/${lang}`}/place/${country}/${city}`
						: country
							? `${lang === 'sv' ? '' : `/${lang}`}/place/${country}`
							: `${lang === 'sv' ? '' : `/${lang}`}/place`
				navigate({
					pathname: path,
					search: newSearchParams.toString(),
				})
			} else {
				newSearchParams.set('country', country)
				newSearchParams.set('city', city)
				setSearchParams(newSearchParams)
			}
		} else if (!isPlacePage && !isMapPage && !isRootPage) {
			navigate({
				pathname: `${lang === 'sv' ? '' : `/${lang}`}/place`,
				search: newSearchParams.toString(),
			})
		} else {
			if (!isMapPage) {
				navigate({
					pathname: lang === 'sv' ? '/' : `/${lang}`,
					search: newSearchParams.toString(),
				})
			} else {
				setSearchParams(newSearchParams)
			}
		}
		if (locationQuery && !placeResult) {
			navigate({
				search: newSearchParams.toString(),
			})
		}

		setShowModal(false)
	}

	const clearSearch = () => {
		setCategories([])
		setSearchText(undefined)
		setFromDate(null)
		setToDate(null)
		setPlaceResult(null)
		if (isMapPage) {
			const swLat = searchParams.get('swLat')
			const swLng = searchParams.get('swLng')
			const neLat = searchParams.get('neLat')
			const neLng = searchParams.get('neLng')
			const newSearchParams = new URLSearchParams()

			if (swLat && swLng && neLat && neLng) {
				newSearchParams.set('swLat', parseFloat(swLat).toFixed(8))
				newSearchParams.set('swLng', parseFloat(swLng).toFixed(8))
				newSearchParams.set('neLat', parseFloat(neLat).toFixed(8))
				newSearchParams.set('neLng', parseFloat(neLng).toFixed(8))
			}
			setSearchParams(newSearchParams)
		} else {
			navigate({
				pathname: `${lang === 'sv' ? '' : `/${lang}`}`,
			})
		}
		setShowModal(false)
	}
	const baseSearchClass =
		'relative mx-0 flex h-12 w-full cursor-pointer items-center rounded-full border border-foreground/20 bg-card pr-10 text-center text-body-sm shadow-md duration-200 hover:shadow-lg md:h-10 lg:w-[500px]'

	return (
		<>
			{showSearch ? (
				<>
					<div className={cn(baseSearchClass, 'justify-evenly md:w-[450px]')}>
						<button
							type="button"
							className="ml-0.5 w-4/12 truncate rounded-full p-2 duration-100 hover:bg-secondary/30"
							onClick={() => {
								setSelectedTab(0)
								setShowModal(true)
							}}
						>
							{getSearchText}
						</button>
						<button
							type="button"
							className="w-4/12 truncate rounded-full p-2 duration-100 hover:bg-secondary/30"
							onClick={() => {
								setSelectedTab(1)
								setShowModal(true)
							}}
						>
							{getLocationText}
						</button>
						<button
							type="button"
							className="w-4/12 truncate rounded-full p-2 px-1 duration-100 hover:bg-secondary/30"
							onClick={() => {
								setSelectedTab(2)
								setShowModal(true)
							}}
						>
							{getDatesText}
						</button>
						<div className="absolute right-[4px] top-[3px]">
							<Button
								variant="secondary"
								size="sm"
								className="!h-10 !w-10 !rounded-full !p-3 md:!h-8 md:!w-8 md:!p-2"
								title={t('action_search_and_filter')}
								type="button"
								onClick={() => {
									setSelectedTab(0)
									setShowModal(true)
								}}
							>
								<Icon name="search" size="lg" />
							</Button>
						</div>
					</div>
				</>
			) : (
				<>
					<button
						className={cn(
							baseSearchClass,
							'justify-start pl-6 duration-100 hover:bg-secondary/20 md:w-[300px] lg:w-[350px]',
						)}
						onClick={() => {
							setSelectedTab(0)
							setShowModal(true)
						}}
					>
						<Text as="sm">{t('action_start_new_search')}</Text>
						<div className="absolute right-[4px] top-[3px]">
							<div className="!h-10 !w-10 !rounded-full bg-secondary !p-3 text-xs md:!h-8 md:!w-8 md:!p-2">
								<Icon name="search" size="sm" className="text-white" />
							</div>
						</div>
					</button>
				</>
			)}

			{showModal && (
				<Modal
					title={t('action_search_and_filter')}
					onClose={() => setShowModal(false)}
					size="lg"
					position="top"
				>
					<Form onSubmit={search}>
						<TabMenu>
							<Tab
								name={t('action_search')}
								index={0}
								dirty={searchText !== undefined || categories.length > 0}
								selectedTab={selectedTab}
								setSelectedTab={setSelectedTab}
							/>
							<Tab
								name={t('misc_place')}
								index={1}
								dirty={placeResult}
								selectedTab={selectedTab}
								setSelectedTab={setSelectedTab}
							/>
							<Tab
								name={t('misc_dates')}
								index={2}
								dirty={fromDate !== null || toDate !== null}
								selectedTab={selectedTab}
								setSelectedTab={setSelectedTab}
							/>
						</TabMenu>

						<div className="content py-6">
							<div className="mx-auto max-w-xl">
								{selectedTab === 0 && (
									<>
										<HeaderSearchText
											searchText={searchText}
											setSearchText={setSearchText}
										/>
										<HeaderSearchCategories
											allCategories={allCategories}
											categories={categories}
											setCategories={setCategories}
										/>
									</>
								)}
								{selectedTab === 1 && (
									<>
										<HeaderSearchPlaces
											resolution="cities"
											language={lang}
											setPlaceResult={setPlaceResult}
											locationQuery={capitalizeText(
												locationQuery ?? placeResult?.name ?? '',
											)}
										/>
									</>
								)}
								{selectedTab === 2 && (
									<>
										<HeaderSearchDate
											fromDate={fromDate ? new Date(fromDate) : null}
											toDate={toDate ? new Date(toDate) : null}
											setFromDate={setFromDate}
											setToDate={setToDate}
										/>
									</>
								)}
							</div>
						</div>

						<div className="col-span-12 border-t border-foreground/20"></div>

						<div className="mt-4 flex justify-between">
							<Button
								type="button"
								onClick={clearSearch}
								variant="primary"
								size="lg"
							>
								{t('action_clear_search')}
							</Button>
							<Button type="submit" variant="secondary" size="lg">
								{t('action_search')}
							</Button>
						</div>
					</Form>
				</Modal>
			)}
		</>
	)
}

function TabMenu({ children }: { children: React.ReactNode }) {
	return (
		<div className="flex justify-center">
			<div className="flex w-full max-w-xl rounded-3xl border border-border bg-card">
				{children}
			</div>
		</div>
	)
}

function Tab({
	name,
	index,
	selectedTab,
	setSelectedTab,
	dirty,
}: {
	name: string
	index: number
	selectedTab: number
	setSelectedTab: (index: number) => void
	dirty?: boolean
}) {
	return (
		<button
			type="button"
			className={`tab w-full cursor-pointer rounded-3xl p-2 text-center text-sm duration-100 hover:!bg-secondary/80 hover:!text-white ${selectedTab === index && '!bg-secondary/80 text-white'} ${dirty ? 'bg-secondary/20' : ''}`}
			onClick={() => setSelectedTab(index)}
		>
			{name}
		</button>
	)
}
