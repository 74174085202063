import { Link, useLocation } from 'react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import Modal from '../modal/modal'
import { useClientLang } from '~/utils/i18n.utils'
import { cn } from '~/utils/misc'

export default function LanguageSwitch({
	showText = false,
}: {
	showText?: boolean
}) {
	const { t } = useTranslation()
	const location = useLocation()
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const lng = useClientLang()

	const pathname = location.pathname

	// List of language codes
	const languageCodes = ['en', 'sv', 'da', 'no', 'fi']

	// Remove existing language prefix from pathname
	const segments = pathname.split('/')
	if (languageCodes.includes(segments[1])) {
		segments.splice(1, 1)
	}
	const cleanPathname = segments.join('/') || '/'

	const languageLinks = [
		{
			title: 'English',
			href: `/en${cleanPathname === '/' ? '' : cleanPathname}`,
			code: 'en',
		},
		{
			title: 'Svenska',
			href: `${cleanPathname === '/' ? '' : cleanPathname}`,
			code: 'sv',
		},
		{
			title: 'Dansk',
			href: `/da${cleanPathname === '/' ? '' : cleanPathname}`,
			code: 'da',
		},
		{
			title: 'Norsk',
			href: `/no${cleanPathname === '/' ? '' : cleanPathname}`,
			code: 'no',
		},
		{
			title: 'Suomi',
			href: `/fi${cleanPathname === '/' ? '' : cleanPathname}`,
			code: 'fi',
		},
	]
	const toggleModal = () => setModalIsOpen(prev => !prev)
	return (
		<>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					title={t('action_pick_language')}
					size="lg"
				>
					<div className="mt-4 grid grid-cols-12 gap-4">
						{languageLinks.map((x, i) => (
							<Link
								key={i}
								className={cn(
									'col-span-12 rounded-xl border p-4 text-center hover:bg-secondary/5 md:col-span-6 lg:col-span-4',
									lng.lngShort === x.code
										? 'border-secondary'
										: 'border-border',
								)}
								to={x.href}
								onClick={toggleModal}
								reloadDocument
							>
								<div className="flex items-center">
									<div className="mr-2 h-6 w-6 overflow-hidden rounded-full">
										<img
											src={`/locales/${x.code.toLowerCase()}/1x1.svg`}
											alt={`${x.title} flag`}
											className="h-full w-full object-cover"
										/>
									</div>
									<span className="text-body-md">{x.title}</span>
								</div>
							</Link>
						))}
					</div>
				</Modal>
			)}
			<Button
				onClick={toggleModal}
				variant="ghost"
				className="gap-x-2 !rounded-full"
			>
				<Icon name="globe" className="text-body-md">
					<span className="sr-only">{t('action_change_language')}</span>
					{showText && t('misc_language')}
				</Icon>
				<span className="text-xs font-bold">{lng.lngShort.toUpperCase()}</span>
			</Button>
		</>
	)
}
