import Confetti from 'react-confetti'

/**
 * confetti is a unique random identifier which re-renders the component
 */
export function ConfettiComponent({ confetti }: { confetti?: string }) {
	if (!confetti) return null

	return (
		<Confetti
			key={confetti}
			run={Boolean(confetti)}
			recycle={false}
			numberOfPieces={500}
			width={window.innerWidth}
			height={window.innerHeight}
		/>
	)
}
