import { useTranslation } from 'react-i18next'
import Container from '../container/container'
import { Icon } from '../icon/icon'
import { Link } from '../internal-link/Internal-link'
import LanguageSwitch from '../language-switch/language-switch'
import Text from '../text/text'
import { cn } from '~/utils/misc'

export default function Footer({ classes }: { classes?: string | undefined }) {
	const { t } = useTranslation()
	return (
		<footer
			className={cn(
				'bottom-0 z-10 block border-t bg-background p-4 pb-20 text-foreground md:pb-4',
				classes,
			)}
		>
			<Container
				marginY={false}
				className="flex flex-col items-center justify-between py-2 md:flex-row"
			>
				<div className="flex flex-col items-center gap-x-6 md:flex-row">
					<Text className="hidden text-xl font-extrabold tracking-wide md:block">
						<span className="text-foreground">EVENT</span>
						<span className="text-secondary">LY</span>
					</Text>
					<div className="flex flex-col font-light max-md:text-center md:flex-row">
						<Link to="/about" className="max-md:my-2 md:mr-3">
							{t('about_us_title')}
						</Link>
						<Link to="/terms-and-conditions" className="max-md:my-2 md:mx-3">
							{t('terms_and_conditions_terms')}
						</Link>
						<Link to="/privacy-policy" className="max-md:my-2 md:mx-3">
							{t('misc_integrity')}
						</Link>
						<Link to="/promote-event" className="max-md:my-2 md:mx-3">
							{t('misc_promote_event')}
						</Link>
						<div className="mb-4 md:hidden">
							<LanguageSwitch showText />
						</div>
					</div>
				</div>
				<div className="text-md flex flex-col gap-2 max-md:mx-auto max-md:text-center md:flex-row">
					<div className="flex flex-row justify-between gap-x-2 text-body-lg max-md:mb-2 md:!text-body-md">
						<a
							target="_blank"
							rel="noreferrer"
							title="Evently på Facebook"
							aria-label="Evently på Facebook"
							href="https://www.facebook.com/eventlyse"
						>
							<Icon
								name="facebook-f"
								className="duration-100 hover:text-secondary"
							/>
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							title="Evently på TikTok"
							aria-label="Evently på TikTok"
							href="https://www.tiktok.com/@eventlyse"
						>
							<Icon
								name="tiktok"
								className="duration-100 hover:text-secondary"
							/>
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							title="Evently på LinkedIn"
							aria-label="Evently på LinkedIn"
							href="https://www.linkedin.com/company/evently-ab"
						>
							<Icon
								name="linkedin"
								className="duration-100 hover:text-secondary"
							/>
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							title="Evently på Instagram"
							aria-label="Evently på Instagram"
							href="https://www.instagram.com/evently_se"
						>
							<Icon
								name="instagram"
								className="duration-100 hover:text-secondary"
							/>
						</a>
					</div>
				</div>
			</Container>
		</footer>
	)
}
