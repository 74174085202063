/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import { Input } from '../ui/input'

export default function HeaderSearchText({
	searchText,
	setSearchText,
}: {
	searchText: string | undefined
	setSearchText: (result: any | null) => void
}) {
	const [text, setText] = useState<string>(searchText ?? '')
	const { t } = useTranslation()
	const handleChange = (value?: string) => {
		if (value) {
			setText(value)
			setSearchText(value)
		} else {
			setText('')
			setSearchText(undefined)
		}
	}

	const clearInput = () => {
		setText('')
		setSearchText(undefined)
	}

	return (
		<div className="relative z-10 w-full bg-background">
			<Input
				type="text"
				value={text}
				placeholder={t('form_label_search_event')}
				autoFocus
				onChange={x => handleChange(x.target.value)}
			/>
			{searchText && (
				<div className="absolute right-[3.5px] top-[2.5px]">
					<Button
						onClick={clearInput}
						variant="primary"
						size="sm"
						className="!h-9 !w-9 animate-fade !rounded-full !p-2.5"
						tabIndex={-1}
						title={t('action_reset_location_search')}
						type="button"
					>
						<Icon name="cross" size="lg" />
					</Button>
				</div>
			)}
		</div>
	)
}
