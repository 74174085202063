import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../icon/icon'
import './styles.css'
import { useClientLang } from '~/utils/i18n.utils'

type NullableDate = Date | null
type DateRangeValue = NullableDate | [NullableDate, NullableDate]

export default function HeaderSearchDate({
	fromDate,
	toDate,
	setFromDate,
	setToDate,
}: {
	fromDate: NullableDate
	toDate: NullableDate
	setFromDate: (date: NullableDate) => void
	setToDate: (date: NullableDate) => void
}) {
	const [dates, setDates] = useState<DateRangeValue>(
		!fromDate && !toDate ? null : [fromDate, toDate],
	)

	const { t } = useTranslation()
	const lng = useClientLang()
	const onChange = (dates: DateRangeValue) => {
		if (dates === null) {
			setDates(null)
			setFromDate(null)
			setToDate(null)
		} else {
			setDates(dates)

			if (dates instanceof Date) {
				setFromDate(dates)
				setToDate(dates)
			} else if (dates !== null) {
				setFromDate(dates[0]!)
				setToDate(dates[1]!)
			}
		}
	}
	const onDayChange = (date: Date) => {
		setFromDate(date)
		setToDate(date)
	}
	const today = new Date()

	const oneWeekFromNow = new Date()
	oneWeekFromNow.setDate(today.getDate() + 7)

	const oneMonthFromNow = new Date()
	oneMonthFromNow.setMonth(today.getMonth() + 1)

	const badgeClasses =
		'mr-2 mt-2 rounded-full border border-primary/60 bg-primary/5 px-4 py-2 text-xs font-medium text-foreground hover:bg-primary/10 transition-colors duration-100'

	return (
		<div className="date-search-container">
			<div className="mb-2">
				<button
					onClick={() => onChange(null)}
					className={badgeClasses}
					type="button"
				>
					{t('misc_all_dates')}
				</button>
				<button
					onClick={() => onChange([today, oneWeekFromNow])}
					className={badgeClasses}
					type="button"
				>
					{t('form_label_add_week')}
				</button>
				<button
					onClick={() => onChange([today, oneMonthFromNow])}
					className={badgeClasses}
					type="button"
				>
					{t('misc_add_month')}
				</button>
			</div>
			<DateRangePicker
				className={'header-date-search'}
				onChange={onChange}
				calendarProps={{
					onClickDay: onDayChange,
					showWeekNumbers: true,
					minDetail: 'month',
					prev2Label: null,
					next2Label: null,
				}}
				value={dates}
				calendarIcon={<Icon name="calendar" />}
				clearIcon={<Icon name="cross" />}
				minDate={new Date()}
				maxDate={new Date(new Date().setDate(new Date().getDate() + 730))}
				calendarAriaLabel="Datum"
				format="dd-MM-yyyy"
				isOpen={true}
				locale={lng.lngLong}
			/>
		</div>
	)
}
