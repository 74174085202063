import { useFetcher, useLocation } from 'react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../button/button'
import { StatusButton } from '../button/status-button'
import Modal from '../modal/modal'
import Text from '../text/text'
import Toggle from '../toggle/Toggle'
import { Link } from '~/components/internal-link/Internal-link'
import { useIsFetching } from '~/utils/misc'

export default function CookieBanner() {
	const fetcher = useFetcher()
	const isLoading = useIsFetching(fetcher)
	const location = useLocation()
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)
	const [allowGoogleAnalytics, setAllowGoogleAnalytics] = useState(true)
	const toggleCookieModal = () => setShowModal(prev => !prev)
	const toogleGoogleAnalytics = () => setAllowGoogleAnalytics(prev => !prev)

	return (
		<section className="w-1/1 fixed bottom-2 left-2 right-4 z-20 rounded-lg border border-foreground/30 bg-background p-6 shadow-2xl md:w-3/4 md:p-8 lg:w-2/4">
			{showModal && (
				<Modal onClose={toggleCookieModal} title={t('cookies_your_integrity')}>
					<div className="flex flex-col gap-y-4">
						<Text>{t('cookies_explanation')}</Text>
						<div>
							<Text as="h3">{t('cookies_required')}</Text>
							<Text>{t('cookies_required_explained')}</Text>
						</div>
						<div className="flex flex-col gap-4">
							<Toggle
								checked={true}
								onChange={() => {}}
								disabled
								label={t('cookies_security')}
							/>
							<Toggle
								checked={true}
								onChange={() => {}}
								disabled
								label={t('cookies_user_experience')}
							/>
						</div>
						<div>
							<Text as="h3">{t('cookies_pointed_cookies')}</Text>
							<Text>{t('cookies_pointed_cookies_explained')}</Text>
						</div>
						<div className="flex flex-col gap-4">
							<Toggle
								checked={allowGoogleAnalytics}
								onChange={toogleGoogleAnalytics}
								label="Google analytics"
							/>
						</div>
						<fetcher.Form
							preventScrollReset
							method="POST"
							action="/api/cookies"
						>
							<input
								hidden
								readOnly
								type="hidden"
								name="pathname"
								value={location.pathname + location.search}
							/>
							<input
								type="hidden"
								name="acceptGa"
								value={allowGoogleAnalytics ? 'true' : 'false'}
							/>
							<StatusButton
								name="acceptGdpr"
								value="true"
								type="submit"
								status={isLoading ? 'pending' : 'idle'}
							>
								{t('action_save')}
							</StatusButton>
						</fetcher.Form>
					</div>
				</Modal>
			)}
			<fetcher.Form
				className="flex flex-col gap-2 md:gap-4"
				method="POST"
				action="/api/cookies"
				preventScrollReset
			>
				<input
					hidden
					readOnly
					type="hidden"
					name="pathname"
					value={location.pathname + location.search}
				/>
				<div>
					<Text as="h3">{t('cookies_your_integrity')}</Text>
					<Text>
						{t('cookies_we_use_cookies_p1')}{' '}
						<Link className="font-semibold underline" to="/privacy-policy">
							{t('cookies_cookie_policy')}
						</Link>
						. {t('cookies_we_use_cookies_p2')}
					</Text>
				</div>
				<div className="mt-4 flex flex-row justify-end gap-4">
					<Button
						size="lg"
						variant="ghost"
						onClick={toggleCookieModal}
						type="button"
					>
						{t('cookies_settings')}
					</Button>
					<StatusButton
						size="lg"
						name="acceptAll"
						value="true"
						type="submit"
						status={isLoading ? 'pending' : 'idle'}
					>
						{t('misc_ok')}
					</StatusButton>
				</div>
			</fetcher.Form>
		</section>
	)
}
